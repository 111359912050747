import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useAtom } from "jotai";
import { userAtom } from "../stores/global";
import { useUser, logout } from "../hooks/userHook";
import { _fetch } from "../utils/helper";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import useAnalytics from "../hooks/useAnalytics";
//import posthog from "posthog-js";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavBar, setShowNavBar] = useState(false);
  //hide menu when clicked outside of it
  const router = useRouter();
  const [user, setUser] = useAtom(userAtom);
  useUser();
  let { logEvent, reset } = useAnalytics();
  const posthog = usePostHog();
  const isSubscriptionEnabled = useFeatureFlagEnabled("subscription");

  const logoutHandler = async () => {
    let user = await _fetch("/api/auth/signout", {
      method: "POST",
    });
    logEvent("logout");
    reset();
    setUser({ loggedIn: false });
  };

  useEffect(() => {
    // setIsOpen(false);

    router.events.on("routeChangeStart", (url, { shallow }) => {
      // console.log(`routing to ${url}`, `is shallow routing: ${shallow}`);
      // console.log("useEffect", isOpen);
      setIsOpen(false);
    });
  }, [router.pathname]);

  return (
    <header className="section-header ">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="/">
            <img
              src="/images/horizontal_logo_name.png"
              className="logo"
              alt="bulbuli logo"
            />
          </a>
          <button
            className={"navbar-toggler " + (showNavBar ? "" : "collapsed")}
            type="button"
            onClick={() => setShowNavBar(!showNavBar)}
          >
            <i
              className="fa fa-bars white-text"
              style={{ color: "#a39fde" }}
            ></i>
          </button>
          <div
            className={
              "navbar-collapse collapse justify-content-between " +
              (showNavBar ? "show" : "")
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item mr-auto">
                <Link href={"/zgaprebi"}>
                  <a className="nav-link">ზღაპრები ქართულად</a>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  onClick={() => setIsOpen(!isOpen)}
                  // style={{ backgroundColor: "white", color: "" }}
                  className="nav-link dropdown-toggle"
                  type="button"
                >
                  კატალოგი
                </a>

                <div className={"dropdown-menu " + (isOpen ? "show" : "kata")}>
                  <Link href={"/zgaprebi/ქართული-ხალხური"}>
                    <a
                      className={
                        "dropdown-item " +
                        (router.query.collectionPage == "ქართული-ხალხური"
                          ? "nav-active"
                          : "")
                      }
                    >
                      ქართული ხალხური
                    </a>
                  </Link>
                  <Link href={"/zgaprebi/შარლ-პეროს-ზღაპრები"}>
                    <a
                      className={
                        "dropdown-item " +
                        (router.query.collectionPage == "შარლ-პეროს-ზღაპრები"
                          ? "nav-active"
                          : "")
                      }
                    >
                      შარლ პერო
                    </a>
                  </Link>
                  <Link href={"/zgaprebi/ძმები-გრიმების-ზღაპრები"}>
                    <a
                      className={
                        "dropdown-item " +
                        (router.query.collectionPage ==
                        "ძმები-გრიმების-ზღაპრები"
                          ? "nav-active"
                          : "")
                      }
                    >
                      ძმები გრიმები
                    </a>
                  </Link>
                  <Link href={"/zgaprebi/ანდერსენის-ზღაპრები"}>
                    <a
                      className={
                        "dropdown-item " +
                        (router.query.collectionPage == "ანდერსენის-ზღაპრები"
                          ? "nav-active"
                          : "")
                      }
                    >
                      ანდერსენი
                    </a>
                  </Link>
                  <Link href={"/zgaprebi/მსოფლიო-კლასიკა"}>
                    <a
                      className={
                        "dropdown-item " +
                        (router.query.collectionPage == "მსოფლიო-კლასიკა"
                          ? "nav-active"
                          : "")
                      }
                    >
                      მსოფლიო კლასიკა
                    </a>
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav">
              {!user.loggedIn &&
                (isSubscriptionEnabled ? (
                  <li>
                    <a
                      className="btn btn-green"
                      href="/auth/register"
                      data-attr="register_header"
                    >
                      რეგისტრაცია
                    </a>
                  </li>
                ) : (
                  <li>
                    <a
                      href="https://link.bulbuli.ge/web"
                      target="_blank"
                      className="btn btn-green"
                    >
                      გადმოწერეთ აპლიკაცია
                    </a>
                  </li>
                ))}
              <li>
                {user.loggedIn ? (
                  <Link href={"/auth/profile"}>
                    <a className="nav-link mr-1">{user.username}</a>
                  </Link>
                ) : (
                  <Link href={"/auth/login"}>
                    <a className="nav-link mr-1">შესვლა</a>
                  </Link>
                )}
              </li>
              {user.loggedIn && (
                <li>
                  <button
                    onClick={() => {
                      logoutHandler();
                    }}
                    className="btn btn-outline-primary ml-1"
                  >
                    გამოსვლა
                  </button>
                  {/* <Link href={"/auth/login"}>
                    <a className="btn btn-outline-primary ml-1">გამოსვლა</a>
                  </Link> */}
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
