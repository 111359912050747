import { useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import posthog from "posthog-js";
import Mixpanel from "mixpanel";

const logEnabled = process.env.NODE_ENV == "development" ? true : true;

const useAnalytics = () => {
  let mixpanel;
  //let posthog = usePostHog();
  useEffect(() => {
    console.log("useAnalytics");
    //mixpanel = Mixpanel.init(process.env.MIXPANEL_TOKEN);
    posthog.init(process.env.POSTHOG_KEY, {
      api_host: process.env.POSTHOG_URL || "https://app.posthog.com",
      loaded: (posthog) => {},
    });
  }, []);

  const setUserId = async (id, props, tracker = "all") => {
    //await analytics().setUserId(id);
    //mixpanel.identify(id);
    posthog.identify(id);
    

    //setUserProperties({ Email: props.email }, "firebase");

    //if (tracker == "all") setUserProperties({ plan: props.plan });

    if (tracker == "mixpanel")
      setUserProperties({ $email: props.email, $name: props.name }, "mixpanel");
    if (tracker == "posthog")
      setUserProperties({ email: props.email, name: props.name }, "posthog");
  };

  let logEvent = (event, props) => {
    console.log("logEvent", event);
    try {
      // mixpanel.track(event, { ...props });
      posthog.capture(event, { ...props });
    } catch (error) {
      console.log("logEvent", error);
    }
  };

  const setUserProperties = async (properties, tracker = "all") => {
    if (logEnabled) {
      //   if (tracker == "all" || tracker == "firebase")
      //     await analytics().setUserProperties(properties);
      // console.log('properties', properties);
      if (tracker == "all")
        Object.entries(properties).map((item) => {
          mixpanel.getPeople().set(item[0], item[1]);
          posthog.people.set(item[0], item[1]);
        });

      if (tracker == "mixpanel")
        Object.entries(properties).map((item) => {
          mixpanel.getPeople().set(item[0], item[1]);
        });

      if (tracker == "posthog")
        Object.entries(properties).map((item) => {
          posthog.people.set(item[0], item[1]);
        });
    }
  };

  const reset = () => {
    //mixpanel.reset();
    posthog.reset();
  };

  return { logEvent, setUserId, setUserProperties, reset };
};

export default useAnalytics;
