import React, { useEffect, useState, useRef } from "react";
import ReactAudioPlayer from "react-audio-player";
import Hls from "hls.js";
import cookieCutter from "cookie-cutter";
import { useAtom } from "jotai";
import { playerAtom } from "../../stores/global";
import { userAtom } from "../../stores/global";
import Link from "next/link";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import DownloadSmall from "../downloadSmall";

const TrackPlayer = ({ data }) => {
  const [player, setPlayer] = useAtom(playerAtom);
  const [user, setUser] = useAtom(userAtom);
  const storyPlayerRef = useRef(null);
  const samplePlayerRef = useRef(null);
  const sampleFolder =
    "https://istoriebi-static.s3.eu-central-1.amazonaws.com/samples/";
  const [currentTrack, setCurrentTrack] = useState(null);
  const isSubscriptionEnabled = useFeatureFlagEnabled("subscription");

  const onPlay = (e) => {
    //setPlayer({ playing: true });
    setPlayer((prev) => ({ ...prev, playing: true }));
    //console.log("onPlay", e);
  };

  const onPause = (e) => {
    //setPlayer({ playing: true });
    setPlayer((prev) => ({ ...prev, playing: false }));
    //console.log("onPause", e);
  };

  useEffect(() => {
    let audio;
    let hls;
    console.log("Big useEffect");
    if (user.loggedIn && user.isPremium) {
      if (storyPlayerRef.current && player.audioURL) {
        audio = storyPlayerRef.current;
        const initTime = Date.now();

        if (audio.canPlayType("application/vnd.apple.mpegurl")) {
          // This will run in safari, where HLS is supported natively
          audio.src = player.audioURL;
        } else if (Hls.isSupported()) {
          // This will run in all other modern browsers
          hls = new Hls();
          hls.loadSource(player.audioURL + "?jwt=" + cookieCutter.get("token"));
          hls.attachMedia(audio);
          //audio.play();
          if (player.action == "play") {
            storyPlayerRef.current.play();
            setPlayer((prev) => ({
              ...prev,
              playing: true,
            }));
          } else {
            storyPlayerRef.current.pause();
            setPlayer((prev) => ({
              ...prev,
              playing: false,
            }));
          }
        }
      }
    } else {
      console.log("samplePlayerRef.current", samplePlayerRef.current);
      if (samplePlayerRef.current) {
        audio = samplePlayerRef.current;
        console.log("currentTrack", currentTrack, player.id);
        console.log("player.action", player.action);

        audio.src = sampleFolder + player.sample;
        if (player.action == "play") {
          samplePlayerRef.current.play();
          setPlayer((prev) => ({
            ...prev,
            playing: true,
          }));
        } else {
          samplePlayerRef.current.pause();
          setPlayer((prev) => ({
            ...prev,
            playing: false,
          }));
        }
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [
    storyPlayerRef,
    samplePlayerRef,
    player.action,
    player.id,
    user.isPremium,
  ]);

  // useEffect(() => {
  //   console.log("Small useEffect",);
  //   if (player.status == "playing") {
  //     samplePlayerRef.current.play();
  //   }
  //   if (player.status == "paused") {
  //     samplePlayerRef.current.pause();
  //   }
  //   //console.log("useEffect", player.playing);
  // }, [player.status]);
  // useEffect(() => {

  //   if (samplePlayerRef.current && samplePlayerRef.current.paused) {
  //     console.log("audio.paused", audio.paused);
  //     samplePlayerRef.current.play();
  //   } else {
  //     samplePlayerRef.current.pause();
  //   }
  // }, [player.playing]);

  return (
    <>
      {player.id != "" && (
        <div className="track-player-container fixed-bottom">
          <div
            style={{
              backgroundColor: "#373985",
            }}
          >
            <div className="container">
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <div style={{ marginBottom: 10 }}>
                  <span className="font-weight-bold"> {player.title}</span>
                </div>

                {user.loggedIn && user.isPremium ? (
                  <audio
                    controls
                    ref={storyPlayerRef}
                    onPlay={onPlay}
                    onPause={onPause}
                  ></audio>
                ) : (
                  <audio
                    id="audio"
                    controls
                    ref={samplePlayerRef}
                    onPlay={onPlay}
                    onPause={onPause}
                  ></audio>
                )}

                {isSubscriptionEnabled ? (
                  <>
                    {!user.loggedIn && (
                      <Link href="/auth/register" data-attr="register_player">
                        <div
                          className="d-flex flex-row justify-content-center align-items-center"
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{ maxWidth: 200 }}
                            className="white-text"
                          >
                            მოუსმინეთ ზღაპრებს შეზღუდვის გარეშე
                          </span>

                          <a className="btn btn-download-small">
                            <i className="fa fa-arrow-right arrow"></i>
                          </a>
                        </div>
                      </Link>
                    )}
                    {user.loggedIn && !user.isPremium && (
                      <Link href="/auth/profile" data-attr="register_player">
                        <div
                          className="d-flex flex-row justify-content-center align-items-center"
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            cursor: "pointer",
                          }}
                        >
                          <span
                            style={{ maxWidth: 200 }}
                            className="white-text"
                          >
                            მოუსმინეთ ზღაპრებს შეზღუდვის გარეშე
                          </span>

                          <a className="btn btn-download-small">
                            <i className="fa fa-arrow-right arrow"></i>
                          </a>
                        </div>
                      </Link>
                    )}
                  </>
                ) : (
                  <DownloadSmall />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TrackPlayer;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
};
